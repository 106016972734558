/*eslint-disable */
/* global _gaq:true */
define(function (require) {
  const $ = require('jquery');
  const _ = require('underscore');
  const URI = require('URIjs/URI');
  const log = require('common/log');
  const config = require('common/page-config').googleAnalytics;
  const {loadScript} = require('common/load-script');
  const tagManager = require('common/analytics/google-tagmanager');
  const dataLayer = require("common/analytics/dataLayer");
  const utils = require("common/util/wahanda-utils");

  let trackedUtmParameter = false;
  let eventTrackingSuspended = false;
  let trackers;
  let exports = {
    getConfiguredCustomVars: getConfiguredCustomVars,
    resumeEventTracking: resumeEventTracking,
    suspendEventTracking: suspendEventTracking,
    trackEvent: trackEvent,
    trackPageView: trackPageView,
    trackPageViewWithUtm: trackPageViewWithUtm,
    trackTransaction: trackTransaction,
    trackEventToDataLayer: trackEventToDataLayer
  };

  var trackPageViewOnInit = config.pageView !== 'MOBILE';

  initialise();

  function trackEventToDataLayer (category, event, data) {
    if (!event) {
      return;
    }

    dataLayer.track({
      event: utils.toCamelCase(event),
      [utils.toCamelCase(event)]: {
        action: {
          name: event.replace(/\-/g, "_"),
          category: category.replace(/\-/g, "_")
        },
        data: utils.dashedToCamel(data)
      }
    });
  }

  /**
  * Track a page view
  *
  * @param String uri The page uri. If absent, the current window's location will be used.
  *
  * Following the uri parameter, there may be 0 or more arrays, each representing a method to push to _gaq.
  * See https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiBasicConfiguration
  *
  * Examples:
  *         ga.trackPageView('/somewhere/stuff/');
  *         ga.trackPageView('/somewhere/stuff/', ['_setCustomVar', 3, 'page-view', config.pageView, 3], [...]);
  */
  function trackPageView (uri) {
    tagManager.trackMobilePage(uri);

    var methods = Array.prototype.slice.call(arguments).slice(1);
    var customVarIndices = [];

    logInfo('GA trackPageView', uri?uri:document.location.href);

    if (!uri) {
      methods = getConfiguredCustomVars($(document)).concat(methods);
    }

    _.each(methods, function(method) {
      push(method);
      // Make a note of any customer var slots that were used.
      if (method[0] === '_setCustomVar') {
        customVarIndices.push(method[1]);
        logInfo('GA custom var: ', method.slice(1));
      }
    });

   push(['_trackPageview', uri]);

   // Delete any custom vars that were set.
   // (Thus treating them as transient, and just for this page view.)
   _.each(customVarIndices, function(index) {
     push(['_deleteCustomVar', index]);
   });
  }

  function trackPageViewWithUtm (uri, gaCustomVar) {
    var utmParameterName = 'utm_content';

    if (!trackedUtmParameter) {
      uri = addUtmParameterToTrackedUri(uri);
      trackedUtmParameter = true;
    }

    if (gaCustomVar) {
      trackPageView(uri, gaCustomVar);
    } else {
      trackPageView(uri);
    }

    function addUtmParameterToTrackedUri(trackedUri) {
      var utmParameterValue = new URI(window.location.href).query(true)[utmParameterName];

      uri = new URI(trackedUri);

      if (utmParameterValue) {
        uri.addQuery(utmParameterName, utmParameterValue);
      }

      return uri.toString();
    }
  }

  /**
   * category, action and data are required (though data can be null)
   *
   * the remaining arguments are optional
   *
   *  - value (number)
   *  - callback (function)
   *  - async (boolean)
   *
   *
   */
  function trackEvent (category, action, data) {
    if (eventTrackingSuspended) {
      return;
    }

    var callback=null, sync=false, value=null;

    if (arguments.length > 3) {
      for(var i=3; i<arguments.length; i++) {
        var arg = arguments[i];

        if (typeof  arg === 'function') {
          callback = arg;
        } else if (typeof  arg === 'number') {
          value = arg;
        } else if (typeof  arg === 'boolean') {
          sync = arg;
        }
      }
    }

    var label=null;

    if (data === null) {
    } else if (typeof data === 'object') {
      if (!$.isEmptyObject(data)) {
        label = JSON.stringify(data);
      }
    } else if (typeof data === 'number') {
      // GA will not track events where label === 'number'
      label = String(data);
    } else {
      label = data;
    }

    // DEV-7665: Events should not count towards bounce rate
    var nonInteraction = true;

    var ev = ['_trackEvent', category, action, label, value, nonInteraction];

    logInfo('GA: ', ev);

    push(ev);

    if (callback) {
      callback();
    }
  }

  /**
   * Resume event tracking (following an earlier call to suspendEventTracking).
   *
   * This is only needed because of DEV-7725.
   * This function and suspendEventTracking should be removed when that is
   * addressed (following implementation of DEV-7602).
   */
  function resumeEventTracking() {
    eventTrackingSuspended = false;
  }

  /**
   * Do not track events until resumeEventTracking() is called.
   *
   * This is only needed because of DEV-7725.
   * This function and resumeEventTracking should be removed when that is
   * addressed (following implementation of DEV-7602).
   */
  function suspendEventTracking() {
    eventTrackingSuspended = true;
  }

  function exportNops() {
    _.each(exports, function(func, name) {
      exports[name] = function() {};
    });
  }

  function pushConfigValues() {
    var pageView = config.pageView + (config.isApp ? '-' + config.mobileVariantType.replace('_', '-') : '');

    _.each(trackers, function(tracker) {
      window._gaq.push([tracker.prefix + '_setAccount', tracker.code]);
    });

    if (document.domain.indexOf(config.domainName) !== -1) {
      push(['_setDomainName', config.domainName]);
    }

    push(['_setSiteSpeedSampleRate', config.sampleRate]);
    push(['_setCustomVar', 3, 'page-view', pageView, 3]);
  }

  // Any additional custom vars from div.google-analytics-custom-var
  function getConfiguredCustomVars($container) {
    var methods = [];

    $container.filter('.google-analytics-custom-var')
    .add($container.find('.google-analytics-custom-var'))
    .each(function() {
      var data = $(this).data();
      methods.push(['_setCustomVar', data.slot, data.name, String(data.value), data.scope]);
    });

    return methods;
  }

  function trackTransaction(transactionalData) {
    push(['_addTrans',
      transactionalData.transactionId,
      '',
      transactionalData.orderTotal,
      transactionalData.tax || '',
      transactionalData.itemDeliveryCost || '',
      '',
      '',
      ''
    ]);

    _.each(transactionalData.items, function(item) {
      push(['_addItem',
        transactionalData.transactionId,
        item.offerId,
        item.name,
        item.treatmentTypeId,
        item.price,
        item.quantity
      ]);
    });

    push(['_trackTrans']); //submits transaction to the Analytics servers
  }

  function initialise() {
    if (config.enabled) {
      window._gaq = window._gaq || [];

      parseTrackerCodes();
      loadScript('https://ssl.google-analytics.com/ga.js');
      pushConfigValues();

      if (trackPageViewOnInit) {
        trackPageView();
      }
    } else {
      exportNops();
    }

    // Expose "gawahanda" to global scope
    window.gawahanda = exports;
  }

  function push(pushArray) {
    _.each(trackers, function(tracker) {
      var functionName = tracker.prefix + pushArray[0];
      var gaqArray = [functionName].concat(pushArray.slice(1) || []);

      window._gaq.push(gaqArray);
    });
  }

  /**
   * Tracker codes string should be comma separated items, one per tracker.
   *
   * Example:
   *         UA-3992576-1,UA-3992576-16
   */
  function parseTrackerCodes() {
    var trackerCodes = config.trackerCode.split(',');

    trackers = _.map(trackerCodes, function(trackerCode, index) {
      return {
        prefix: index + '.',            // Prefix is necessary for ga to differentiate trackers.
        code: trackerCode
      };
    });
  }

  function logInfo() {
    var args = [log.DEBUG].concat(arguments);
    log.client.apply(this, args);
  }

  return exports;
});
