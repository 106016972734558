export const dashedToCamel = (object, recurse = false) => {
  let newObject = {};

  Object.keys(object).forEach((name) => {
    const nameLength = name.length;
    const value = object[name];

    let camelName = name;

    // go through each char and uppercase the char next to the hyphen
    for (let index = 0; index < nameLength - 1; index++) {
      if (name.charAt(index) === "-") {
        camelName =
          camelName.substr(0, index + 1) +
          camelName.charAt(index + 1).toUpperCase() +
          camelName.substr(index + 2)
        ;
      }
    }

    // remove hyphens
    camelName = camelName.replace(/\-/g, "");

    if (typeof value === "object" && recurse) {
      newObject[camelName] = dashedToCamel(value, recurse);
    } else {
      newObject[camelName] = value;
    }
  });

  return newObject;
};

export const toCamelCase = (value) => {
  return value.toLowerCase().replace(/([-|_][a-z])/g, (s) => {
    return s.replace(/(-|_)/g, "").toUpperCase();
  });
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints;
};

