/*eslint-disable */
define(function (require) {
    var $ = require('jquery');
    var ga = require('common/analytics/google-analytics');

    var COOKIE_EXPIRY_PERIOD_MS = 2 * 60 * 60 * 1000;
    var SITE_VIEW_MODE_COOKIE_NAME = 'svm';
    var SITE_VIEW_MODE_COOKIE_PATH = '/';

    var MOBILE_BROWSE_URI_COOKIE_NAME = 'mbru';

    function addListeners() {
        $(document).on('click', '.switch-site-view-mode-standard' , function siteViewModeStandard(e) {
            e.preventDefault();

            $.cookie(SITE_VIEW_MODE_COOKIE_NAME, 'desktop-override', {
                expires: expiryDate(),
                path: SITE_VIEW_MODE_COOKIE_PATH
            });

            track('standard', $(this));

            return false;
        });

        $('.switch-site-view-mode-mobile').on('click', function siteViewModeMobile(e) {
            e.preventDefault();

            $.cookie(SITE_VIEW_MODE_COOKIE_NAME, 'mobile', {
                expires: expiryDate(),
                path: SITE_VIEW_MODE_COOKIE_PATH
            });

            track('mobile', $(this));
        });
    }

    function expiryDate() {
         var date = new Date();

        date.setTime(date.getTime() + (COOKIE_EXPIRY_PERIOD_MS));
         return date;
    }

    function track(newMode, link) {
        ga.trackEvent('site', 'site-view-mode-change', newMode, function eventComplete() {
            var href;

            if ($.cookie(MOBILE_BROWSE_URI_COOKIE_NAME) !==  null) {
                href = String($.cookie(MOBILE_BROWSE_URI_COOKIE_NAME)).replace('\'','');
            } else if (link.data('href')) {
                href = link.data('href');
            } else {
                href = link.attr('href');
            }

            clearMobileBrowseUriCookie();

            // Only links that go to a different URI should have an href value.
            // This is because we can't force the cache to be ignored if the window.location is
            // set to the same value that it already has.
            if (href) {
                window.location.href = String(href).replace('\'','');
            } else {
                window.location.reload(true);
            }
        });
    }

    function clearMobileBrowseUriCookie() {
        if ($.cookie(MOBILE_BROWSE_URI_COOKIE_NAME) !== null) {
            $.cookie(MOBILE_BROWSE_URI_COOKIE_NAME, null, {path: '/'});
        }
    }

    return {
        addListeners: addListeners,
        clearMobileBrowseUriCookie: clearMobileBrowseUriCookie
    };
});
