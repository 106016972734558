/* eslint-disable camelcase */
import _ from "underscore";
import config from "common/page-config";
import log from "common/log";
import { loadScript } from "../load-script";
const requiredScope = "public_profile,email";

export function initialiseFacebook () {
  if (!config.facebookApi || !config.facebookApi.appId) {
    return Promise.reject(new Error("Missing facebook config values"));
  }
  window.fbAsyncInit = init;
  return loadScript(
    `https://connect.facebook.net/${config.facebookApi.locale}/sdk.js`
  )
    .then(() => {
      return window.FB;
    })
    .catch((e) => {
      log.client(log.INFO, e);
      throw e;
    });
}

function getAccessToken () {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        resolve(response.authResponse.accessToken);
        return;
      }
      reject();
    });
  });
}

function login () {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        if (response.status !== "connected") {
          reject();
          return;
        }
        const grantedScopes = (response.authResponse.grantedScopes || "").split(
          ","
        );
        if (_.contains(grantedScopes, "email")) {
          resolve(response.authResponse.accessToken);
        } else {
          reject("no_email_permission");
        }
      },
      {
        return_scopes: true,
        scope: requiredScope
      }
    );
  });
}

export function getFacebookAuthToken () {
  return getAccessToken().catch(() => login());
}

function init () {
  window.FB.init({
    appId: config.facebookApi.appId,
    status: true,
    cookie: true,
    oauth: true,
    xfbml: true,
    channelUrl: config.facebookApi.channelUrl,
    version: "v2.8"
  });

  // There's almost no use of this. I can't be certain that it's no longer
  // in use anywhere. So leaving it in, for now.
  if (typeof window.fb_onstart === "function") {
    window.fb_onstart();
  }
}
