const gtm = require("common/analytics/google-tagmanager");

function trackEventLoginButtonSuccess (category, network, userTypePriorLogin) {
  if (!network) {
    return gtm.trackEventAsync({
      category,
      property: "treatwell_log_in_button",
      action: "click",
      label: "success"
    });
  }
  return gtm.trackEventAsync({
    category,
    property: `${network}_log_in_button`,
    action: "click",
    label: userTypePriorLogin,
    value: 1
  });
}

function trackEventLoginButtonFailure (category, network, loginStatus) {
  if (!network) {
    return gtm.trackEventAsync({
      category,
      property: "treatwell_log_in_button",
      action: "click",
      label: "fail"
    });
  }
  gtm.trackEventAsync({
    category,
    property: `${network}_log_in_button`,
    action: "click",
    value: 0
  });
  return socialAuthErrorEvent({
    category,
    network,
    error: loginStatus
  });
}

function socialAuthErrorEvent ({ network = "", error, category }) {
  return gtm.trackEventAsync({
    category,
    property: `${network.toLowerCase()}_log_in_button`,
    action: "failure",
    label: error
  });
}

function trackEventPersistentLogin (category, checked) {
  const action = checked ? "select" : "deselect";
  gtm.trackEvent({ category, property: "keep_me_logged_in_toggle", action });
}

function trackEventSignUp (category) {
  gtm.trackEvent({ category, property: "sign_up_button", action: "click" });
}

function trackEventBtnClick (category, property) {
  gtm.trackEvent({ category, property, action: "click" });
}

function trackEventLogInCheckout () {
  var category = "log_in_checkout";
  return {
    ...trackingFunctions(category),
    asGuest: () => gtm.trackEvent({ category, property: "checkout_as_guest_button", action: "click" }),
    socialLoginClick: (provider) => gtm.trackEvent({
      category,
      property: "social_login_button",
      action: "click",
      label: provider
    })
  };
}

function trackingFunctions (category) {
  return {
    persistentToggled: (checked) => trackEventPersistentLogin(category, checked),
    failure: ({ network, loginStatus } = {}) =>
      trackEventLoginButtonFailure(category, network, loginStatus),
    success: ({ network, userTypePriorLogin } = {}) =>
      trackEventLoginButtonSuccess(category, network, userTypePriorLogin),
    email: () =>
      gtm.trackEvent({ category, property: "email_field", action: "focus" }),
    password: () =>
      gtm.trackEvent({ category, property: "password_field", action: "focus" }),
    forgotPassword: () => trackEventBtnClick(category, "reset_password_button"),
    logoutBtnClick: () => trackEventBtnClick(category, "logout_button"),
    loginBtnClick: () => trackEventBtnClick(category, "sign_in_button"),
    notYouBtnClick: () => trackEventBtnClick(category, "not_you_button"),
    socialAuthErrorEvent: ({ network, error }) =>
      socialAuthErrorEvent({ network, error, category })
  };
}

function trackEventLogInHeader () {
  var category = "log_in_header";
  return {
    ...trackingFunctions(category),
    closeBlock: () =>
      gtm.trackEvent({ category, property: "dismiss_cross", action: "click" }),
    signUp: () => trackEventSignUp(category)
  };
}

function trackEventLogInPage () {
  var category = "log_in_page";
  return {
    ...trackingFunctions(category),
    signUp: () => trackEventSignUp(category)
  };
}

function trackRequestPassword () {
  gtm.trackEvent({
    category: "forgotten_password",
    property: "request_button",
    action: "click"
  });
}

module.exports = {
  trackEventLogInHeader,
  trackEventLogInCheckout,
  trackEventLogInPage,
  trackRequestPassword
};
