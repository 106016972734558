/*eslint-disable */
/*
 * Safari in private mode (on both iOS and OS X) does not support
 * localStorage. More to the point it provides the functions, but throws
 * an exception when setItem is called.
 *
 * So if this issue is detected, polyfill the functions using a cookie.
 * thirdparty/local-storage-polyfill.js cannot be used directly, as it
 * detects that localStorage appears to be supported, and does nothing.
 *
 * Instead, the core functionallity from that file is used in here, but
 * the means of detection and using are different.
 * (The licence of that polyfill is MIT.)
 *
 * old file was deleted and this one renamed and used in everyother place
 */

define(function (require) {
    var log = require('common/log');

    function isLocalStorageSupported() {
      var testKey = 'test';

      try {
        localStorage.setItem(testKey, '1');
        localStorage.removeItem(testKey);
        return true;
      } catch (error) {
        return false;
      }
    }

    function Storage(type) {
        return {
            setItem (key, item) {
              $.cookie(key, item, { expires: 7 });
            },

            getItem (key) {

              return $.cookie(key);
            },

            removeItem (key) {
              $.removeCookie(key, { path: '/' });
            },

            clear () {
              // not supported
            }
        };
    }

    function initialise() {
        if (!isLocalStorageSupported()) {
            log.client(log.INFO, 'localStorage.setItem throws exception; providing polyfill');

            var localStorage = new Storage('local');

            // Safari does not appear to support replacing window.localStorage.
            // It allows it to be set, but then ignores the new value.
            // But setting the individual functions does work.
            //
            if (window.localStorage) {
                window.localStorage.clear = localStorage.clear;
                window.localStorage.getItem = localStorage.getItem;
                window.localStorage.setItem = localStorage.setItem;
                window.localStorage.removeItem = localStorage.removeItem;
            }
        }
    }

    return {
        initialise: initialise
    };
});
